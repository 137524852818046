import React, { useState, useEffect, useContext } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    IconButton,
    Stack,
    CardMedia,
    Dialog,
    DialogContent,
    DialogActions,
} from '@mui/material';
import AuthContext from "../context/AuthContext";
import { repairUrl } from "../utils/misc";
import ConfigContext from "../context/ConfigContext";
import { useHistory } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import {useSettings} from "../context/SettingsContext";

const CameraDisplay = ({ data, edit, destroy, configure, editSettings }) => {
    const { permissions } = useContext(AuthContext);
    const { baseUrl } = useContext(ConfigContext);
    const history = useHistory();
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [cardColor, setCardColor] = useState('inherit');
    const { settings } = useSettings()

    const handleImageClick = () => {
        setCurrentImageIndex(0); // Start with the first image
        setOpenImageDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenImageDialog(false);
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.latest_images.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex - 1 + data.latest_images.length) % data.latest_images.length
        );
    };

    useEffect(() => {
        if (!data?.last_fetch) return;

        const checkCardColor = () => {
            const lastFetchTime = new Date(data.last_fetch).getTime();
            const currentTime = Date.now();
            const diffMinutes = (currentTime - lastFetchTime) / (1000 * 60);

            if (diffMinutes > settings?.image_fetch_interval_critical_minutes) {
                setCardColor('#f86a78'); // Red
            } else if (diffMinutes > settings?.image_fetch_interval_warning_minutes) {
                setCardColor('#ffdb6b'); // Yellow
            } else {
                setCardColor('inherit'); // Default
            }
        };

        checkCardColor();
        const interval = setInterval(checkCardColor, 10000);

        return () => clearInterval(interval);
    }, [data?.last_fetch]);

    if (!data || !permissions?.includes('view_camera')) {
        return <Typography>No camera data available</Typography>;
    }

    return (
        <>
            <Card sx={{ backgroundColor: cardColor }}>
            {/*<Card>*/}
                {data.latest_images?.length > 0 && (
                    <CardMedia
                        component="img"
                        height="100%"
                        image={repairUrl(data.latest_images[0], baseUrl)}
                        alt="Camera Image"
                        onClick={handleImageClick}
                        sx={{ cursor: 'pointer' }}
                    />
                )}
                <CardContent>
                    <Typography variant="h4" component="div" gutterBottom>
                        {data.name}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {new Date(data.last_fetch).toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                {permissions?.includes('view_camera') && (
                                    <IconButton
                                        color="primary"
                                        onClick={() => history.push(`/cameras/${data.id}`)}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                )}
                                {permissions?.includes('change_camera') && (
                                    <IconButton
                                        color="primary"
                                        onClick={edit}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                                {permissions?.includes('change_camera') && (
                                    <IconButton
                                        color="primary"
                                        onClick={editSettings}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                )}
                                {permissions?.includes('change_camera') && (
                                    <IconButton
                                        color="primary"
                                        onClick={configure}
                                    >
                                        <BuildIcon />
                                    </IconButton>
                                )}
                                {permissions?.includes('delete_camera') && (
                                    <IconButton
                                        color="error"
                                        onClick={destroy}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {data.latest_images?.length > 0 && (
                <Dialog
                    open={openImageDialog}
                    onClose={handleCloseDialog}
                    fullScreen
                    sx={{
                        '& .MuiDialog-paper': {
                            margin: 0,
                            width: '100vw',
                            height: '100vh',
                            maxWidth: '100vw',
                            maxHeight: '100vh',
                        },
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Dialog Content */}
                    <DialogContent
                        sx={{
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#000',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <img
                            src={repairUrl(data.latest_images[currentImageIndex], baseUrl)}
                            alt={`Camera Image ${currentImageIndex + 1}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </DialogContent>

                    {/* Navigation Buttons */}
                    <DialogActions
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: 0,
                        }}
                    >
                        <IconButton
                            onClick={handlePrevImage}
                            disabled={data.latest_images.length <= 1}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: 'white',
                                margin: 2,
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                },
                            }}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleNextImage}
                            disabled={data.latest_images.length <= 1}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: 'white',
                                margin: 2,
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                },
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </DialogActions>
                </Dialog>
            )}


        </>
    );
};

export default CameraDisplay;
